@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");

.font-custom-style {
  font-family: "Roboto", sans-serif;
  color: #000;
  text-align: justify;
  font-size: 25px;
  font-style: normal;
  line-height: normal;
  font-weight: bolder;
  width: 80%;
  margin: auto;
  padding: 30px 15px;
}

@media only screen and (max-width: 576px) {
  .font-custom-style {
    font-size: 16px; /* Adjust font size for mobile */
    padding: 10px; /* Adjust padding for better readability */
    width: 95%; /* Adjust width for smaller screens */
  }
}
