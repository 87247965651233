@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");

.App {
  display: flex;
  flex-direction: column;
}

.tab-style {
  height: 60px;
  width: 100%;
  color: #000;
  font-size: 28px;
  font-family: "Judson", serif;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  color: #000000;
}

.nav-link:hover {
  color: #000;
}
.nav-link {
  color: #000;
}

.icon-style {
  width: 45px;
  height: 45px;
  margin: 10px auto;
}

.badge-style {
  margin: "-20px auto 5px auto";
  font-size: "1.2rem";
}

@media only screen and (max-width: 576px) {
  .tab-style {
    font-size: 18px; /* Slightly larger for mobile readability */
    padding: 10px; /* Add padding for mobile */
  }
}

/* Adjust font size for iPad Air */
@media screen and (max-width: 820px) and (max-height: 1180px) {
  .tab-style {
    font-size: 20px; /* Adjust font size for iPad Air */
  }
}

/* Adjust font size for iPad Mini */
@media screen and (max-width: 766px) and (max-height: 1024px) {
  .tab-style {
    font-size: 18px; /* Adjust font size for iPad Mini */
  }
}
