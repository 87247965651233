@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");

.publication-custom-style {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", serif;
  font-size: 20px;
  width: 80%;
  margin: auto;
  padding: 20px 15px;
  text-align: justify;
}

/* Individual publication item */
.publication-item {
  margin-bottom: 15px; /* Add space between each publication */
  font-size: 1.1em; /* Slightly larger text for better readability */
}

/* Publication title styling */
.publication-item a {
  font-weight: bold; /* Bold title for better visibility */
  text-decoration: underline; /* Underline the titles */
  color: #000; /* Keep the link color black */
}

/* Hover effect on publication title */
.publication-item a:hover {
  text-decoration: none; /* Remove underline on hover */
  font-size: 1.15em; /* Slightly enlarge on hover */
  color: #333; /* Optional: Change color on hover */
}

/* Highlight your name */
.highlight {
  text-decoration: underline;
  font-weight: bolder;
}

/* Mobile styling adjustments */
@media only screen and (max-width: 576px) {
  .publication-custom-style {
    font-size: 16px; /* Slightly larger for mobile */
    padding: 10px; /* Increase padding for better readability */
    width: 95%; /* Increase width for smaller screens */
  }

  /* Adjust the size and spacing of publication items on smaller screens */
  .publication-item {
    font-size: 1em; /* Adjust to keep readability on smaller screens */
    margin-bottom: 10px; /* Adjust spacing for smaller screens */
  }
}
