@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");
.expertise-custom-style {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", serif;
  font-size: 23px;
  width: 80%;
  margin: auto;
  padding: 30px 15px;
  text-align: justify;
}

@media only screen and (max-width: 576px) {
  .expertise-custom-style {
    font-size: 16px; /* Adjust font size for mobile */
    padding: 10px; /* Add padding for readability */
    width: 95%; /* Adjust width for smaller screens */
  }
}
