@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap);
.App{display:flex;flex-direction:column}.tab-style{height:60px;width:100%;color:#000;font-size:28px;font-family:"Judson",serif;font-style:normal;font-weight:bolder;line-height:normal;color:#000}.nav-link:hover{color:#000}.nav-link{color:#000}.icon-style{width:45px;height:45px;margin:10px auto}.badge-style{margin:"-20px auto 5px auto";font-size:"1.2rem"}@media only screen and (max-width: 576px){.tab-style{font-size:18px;padding:10px}}@media screen and (max-width: 820px)and (max-height: 1180px){.tab-style{font-size:20px}}@media screen and (max-width: 766px)and (max-height: 1024px){.tab-style{font-size:18px}}
.home-page-style{display:flex;justify-content:center;align-items:center;font-family:"Roboto",serif;font-size:27px;width:80%;margin:auto;padding:30px 15px;text-align:justify;font-style:normal;line-height:normal;font-weight:bolder}@media only screen and (max-width: 576px){.home-page-style{font-size:16px;padding:10px;width:95%}}
.font-custom-style{font-family:"Roboto",sans-serif;color:#000;text-align:justify;font-size:25px;font-style:normal;line-height:normal;font-weight:bolder;width:80%;margin:auto;padding:30px 15px}@media only screen and (max-width: 576px){.font-custom-style{font-size:16px;padding:10px;width:95%}}
.publication-custom-style{display:flex;flex-direction:column;font-family:"Roboto",serif;font-size:20px;width:80%;margin:auto;padding:20px 15px;text-align:justify}.publication-item{margin-bottom:15px;font-size:1.1em}.publication-item a{font-weight:bold;text-decoration:underline;color:#000}.publication-item a:hover{text-decoration:none;font-size:1.15em;color:#333}.highlight{text-decoration:underline;font-weight:bolder}@media only screen and (max-width: 576px){.publication-custom-style{font-size:16px;padding:10px;width:95%}.publication-item{font-size:1em;margin-bottom:10px}}
.expertise-custom-style{display:flex;flex-direction:column;font-family:"Roboto",serif;font-size:23px;width:80%;margin:auto;padding:30px 15px;text-align:justify}@media only screen and (max-width: 576px){.expertise-custom-style{font-size:16px;padding:10px;width:95%}}
.resume-custom-style{display:flex;flex-direction:column;justify-content:center;font-family:"Roboto",serif;font-size:25px;width:75%;margin:auto;padding:20px 15px;font-weight:bolder;text-align:center;line-height:normal}.highlight{text-decoration:underline;font-weight:bolder}p{margin-top:30px;line-height:135%}.note-highlight{text-decoration:underline;font-weight:bolder;align-items:baseline}.note-row{font-weight:bold}.date{font-size:21px;margin-top:2px}@media only screen and (max-width: 576px){.font-custom-style{font-size:16px;padding:10px;width:95%}p{margin-top:13px;line-height:135%}.date{font-size:13px;margin-top:2px}}
