@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");
.resume-custom-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto", serif;
  font-size: 25px;
  width: 75%;
  margin: auto;
  padding: 20px 15px;
  font-weight: bolder;
  text-align: center;
  line-height: normal;
}

.highlight {
  text-decoration: underline;
  font-weight: bolder;
}

p {
  margin-top: 30px;
  line-height: 135%;
}

.note-highlight {
  text-decoration: underline;
  font-weight: bolder;
  align-items: baseline;
}

.note-row {
  font-weight: bold;
}

.date {
  font-size: 21px;
  margin-top: 2px;
}

@media only screen and (max-width: 576px) {
  .font-custom-style {
    font-size: 16px; /* Adjust font size for mobile */
    padding: 10px; /* Adjust padding for better readability */
    width: 95%; /* Adjust width for smaller screens */
  }

  p {
    margin-top: 13px;
    line-height: 135%;
  }

  .date {
    font-size: 13px;
    margin-top: 2px;
  }
}
