@import url("https://fonts.googleapis.com/css2?family=Judson&family=Roboto:wght@100&family=Yuji+Hentaigana+Akebono&display=swap");

.home-page-style {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", serif;
  font-size: 27px;
  width: 80%;
  margin: auto;
  padding: 30px 15px; // Add some space around the text on smaller screens
  text-align: justify;
  font-style: normal;
  line-height: normal;
  font-weight: bolder;
}

@media only screen and (max-width: 576px) {
  .home-page-style {
    font-size: 16px; /* Increase size for better mobile readability */
    padding: 10px; /* Add more space around the text */
    width: 95%; /* Expand the width to fit mobile screen */
  }
}
